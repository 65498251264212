import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaRegThumbsUp, FaRegHandshake, FaTruck } from 'react-icons/fa';

// Анимации для точек фона
const moveDots = keyframes`
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(20px, 30px);
  }
  100% {
    transform: translate(0, 0);
  }
`;

// Контейнер для фона с точками
const BackgroundWithDots = styled.div`
  background-color: #f1f1f1;
  position: relative;
  overflow: hidden;

  &::before, &::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.6);
    animation: ${moveDots} 5s infinite ease-in-out;
  }

  &::before {
    width: 15px;
    height: 15px;
    top: 20px;
    left: 30px;
  }

  &::after {
    width: 10px;
    height: 10px;
    bottom: 40px;
    right: 50px;
  }

  .dot {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    animation: ${moveDots} 10s infinite ease-in-out;

    &:nth-child(1) {
      width: 12px;
      height: 12px;
      top: 100px;
      left: 50px;
      animation-duration: 6s;
    }

    &:nth-child(2) {
      width: 10px;
      height: 10px;
      top: 200px;
      left: 200px;
      animation-duration: 8s;
    }

    &:nth-child(3) {
      width: 20px;
      height: 20px;
      top: 300px;
      left: 300px;
      animation-duration: 7s;
    }

    &:nth-child(4) {
      width: 15px;
      height: 15px;
      top: 400px;
      left: 100px;
      animation-duration: 9s;
    }

    &:nth-child(5) {
      width: 8px;
      height: 8px;
      top: 50px;
      right: 50px;
      animation-duration: 5s;
    }
  }
`;

// Анимации
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Стили для контейнера страницы
const AboutContainer = styled.div`
  font-family: 'Montserrat', sans-serif;
  padding: 100px 20px 80px;
  text-align: center;
  color: #333;
  animation: ${fadeIn} 1s ease-in-out;
  position: relative;

  @media (max-width: 768px) {
    padding: 80px 10px 60px;
  }
`;

// Стили для заголовка
const AboutTitle = styled.h1`
  font-size: 42px;
  margin-bottom: 30px;
  color: #ff4500;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

// Стили для текста
const AboutText = styled.p`
  font-size: 22px;
  max-width: 800px;
  margin: 0 auto 50px;
  line-height: 1.6;
  color: #333;

  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 30px;
  }
`;

// Секция для иконок
const IconSection = styled.section`
  display: flex;
  justify-content: center;
  gap: 50px;
  padding: 40px 0;
  background-color: #f1f1f1;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    padding: 30px 0;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  color: #ff4500;
  
  svg {
    font-size: 40px;
    margin-bottom: 15px;

    @media (max-width: 768px) {
      font-size: 30px;
      margin-bottom: 10px;
    }
  }

  p {
    color: #333;
    font-size: 18px;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
`;

// Секция для продуктов с темным фоном
const ProductSection = styled.div`
  background-color: #222;
  color: #fff;
  padding: 50px 20px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 40px 10px;
  }
`;

const ProductTitle = styled.h2`
  font-size: 36px;
  margin-bottom: 30px;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 20px;
  }
`;

const ProductContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
`;

const ProductCard = styled.div`
  background-color: #333;
  padding: 20px;
  margin: 15px;
  width: 250px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  text-align: center;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column; /* Добавляем чтобы карточка растягивалась по высоте */
  justify-content: space-between; /* Выравнивание контента */

  &:hover {
    transform: translateY(-5px);
  }

  h3 {
    color: #ffdd57;
  }

  p {
    color: #fff;
  }

  a {
    background-color: #ff4500;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    margin-top: 10px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #e03e00;
    }

    @media (max-width: 768px) {
      padding: 8px 15px;
      font-size: 14px;
    }
  }
`;

const FAQSection = styled.section`
  background-color: #ffdd57;
  padding: 50px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding: 40px 10px;
  }
`;

const FAQTitle = styled.h2`
  font-size: 36px;
  margin-bottom: 30px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 20px;
  }
`;

const FAQItem = styled.div`
  max-width: 800px;
  margin-bottom: 20px;

  h4 {
    font-size: 22px;
    margin-bottom: 10px;
    color: #333;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  p {
    font-size: 18px;
    color: #444;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
`;

// Главный компонент
const About: React.FC = () => {
  return (
    <BackgroundWithDots>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      
      <AboutContainer>
        <AboutTitle>Про нас</AboutTitle>
        <AboutText>
          Ми - команда професіоналів, яка спеціалізується на створенні високоякісного одягу для сучасних жінок.
          Aifory Brand - це більше, ніж просто бренд. Ми прагнемо створювати одяг, який не тільки підкреслює вашу індивідуальність, але і робить вас впевненими в собі.
          Наша команда працює над тим, щоб кожна річ, яку ви обираєте, стала улюбленою в вашому гардеробі.
        </AboutText>

        <IconSection>
          <IconContainer>
            <FaRegThumbsUp />
            <p>Висока якість для кожної жінки</p>
          </IconContainer>
          <IconContainer>
            <FaRegHandshake />
            <p>Надійні партнерські відносини</p>
          </IconContainer>
          <IconContainer>
            <FaTruck />
            <p>Швидка доставка по всій Україні</p>
          </IconContainer>
        </IconSection>

        <ProductSection>
          <ProductTitle>Наша продукція</ProductTitle>
          <ProductContainer>
            <ProductCard>
              <h3>Елегантна сукня</h3>
              <p>Стиль та комфорт у кожній деталі.</p>
              <a href="/catalog?category=dresses">Переглянути</a>
            </ProductCard>
            <ProductCard>
              <h3>Класичний жакет</h3>
              <p>Надійність та стиль на кожен день.</p>
              <a href="/catalog?category=jackets">Переглянути</a>
            </ProductCard>
            <ProductCard>
              <h3>Спортивний костюм</h3>
              <p>Комфорт для активного способу життя.</p>
              <a href="/catalog?category=sport">Переглянути</a>
            </ProductCard>
          </ProductContainer>
        </ProductSection>

        <FAQSection>
          <FAQTitle>Часті запитання</FAQTitle>
          <FAQItem>
            <h4>Які терміни доставки?</h4>
            <p>Ми доставляємо по всій Україні протягом 3-5 робочих днів.</p>
          </FAQItem>
          <FAQItem>
            <h4>Чи можу я повернути товар?</h4>
            <p>Так, ви можете повернути товар протягом 14 днів після покупки.</p>
          </FAQItem>
          <FAQItem>
            <h4>Які способи оплати доступні?</h4>
            <p>Ми пропонуємо зв'язатися з менеджером через Telegram або використати форму зворотнього зв'язку для уточнення оплати.</p>
          </FAQItem>
        </FAQSection>
      </AboutContainer>
    </BackgroundWithDots>
  );
};

export default About;
